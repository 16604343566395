.container::-webkit-scrollbar {
    width: 7px;
    border-radius: 5px;
}

/* Track */
.container::-webkit-scrollbar-track {
    background: #cbc9c9;
    border-radius: 5px;

}

/* Handle */
.container::-webkit-scrollbar-thumb {
    background: #3e8abd;
    border-radius: 5px;

}

/* Handle on hover */
.container::-webkit-scrollbar-thumb:hover {
    background: #2e76a6;
}