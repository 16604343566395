.card {
    min-width: 291px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: white;
    border-radius: 19px;
    align-items: center;
    box-shadow: 7px 8px 6px 0px #00000040;
    margin-bottom: 10px;
}

.card img {
    width: 245px;
    height: 245px;
    border-radius: 14px;
}

.card .shortDescription {
    font-family: "Montserrat", "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    width: 245px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
}

.card .description {
    font-family: "Montserrat", "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    width: 245px;
    overflow: hidden;
}

.card button {
    align-self: flex-end;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-weight: bold;
    font-family: "Montserrat", "Poppins", sans-serif;
}

.card .bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.modalImage {
    height: 400px;
    width: 400px;
}

.modalDescription {
    max-width: 600px;
}

.modalContainerButtons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}

.modalButtonDownload {
    cursor: pointer;
    font-family: "Montserrat", "Poppins", sans-serif;
    width: 275px;
    height: 45px;
    border-radius: 10px;
    background-color: #F5485D;
    color: white;
    font-size: 12px;
    font-weight: 900;
    border: none;
    transition: all ease-in-out .2s;
}

.modalButtonDownload:hover {
    transform: scale(1.05);
}
