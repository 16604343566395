@import url(./_themes.module.scss);
@import url(./components/index.scss);
body {
    background: linear-gradient(90deg,#fff 9px,transparent 1%) 50%,linear-gradient(#fff 9px,transparent 1%) 50%,#bebebe !important;
    background-size: 10px 10px !important;
    color: #000000;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    max-width: 100vw;
    overflow-x: hidden;
    padding: 0;
    width: 100vw;
}