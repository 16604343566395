@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.containerFilter {
    width: 600px;
    align-self: flex-start;
}

.containerFilter .containerTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.containerFilter .containerTitle h1 {
    font-weight: 900;
    font-size: 55px;
    font-family: "Inter", sans-serif;
    color: #38187B;
    margin: 0;
}

.containerFilter .containerTitle .containerVapt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid #41414180;
    border-radius: 6px;
    height: 32px;
    padding: 5px 15px;
}

.containerFilter .containerTitle .containerVapt div {
    width: 22px;
    height: 22px;
    background-color: #F5485D;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: -5px;
}

.containerFilter .containerTitle .containerVapt p {
    font-size: 12px;
    font-weight: 500;
    color: #414141;
    font-family: "Montserrat", "Poppins", sans-serif;
}

.containerFilter .search {
    width: 100%;
}

.containerPosts {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: row;
    margin-top: 40px;
    width: 100%;
}

.containerPosts .noContent {
    width: 100%;
    text-align: center;
    font-weight: 500;
}

.newPost {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.newPost button {
    font-family: "Montserrat", "Poppins", sans-serif;
    height: 48px;
    background-color: #F5485D;
    color: white;
    font-size: 20px;
    font-weight: 900;
    border: none;
    border-radius: 10px;
    padding: 5px 20px;
    cursor: pointer;
}