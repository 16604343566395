@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.container .arrows {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;

}

.cardContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 30px;
    overflow: auto;
    scroll-behavior: smooth;
    height: 100%;
}

.cardContainer::-webkit-scrollbar {
    width: 7px;
}

.cardContainer::-webkit-scrollbar-track {
    background: transparent;

}

.cardContainer::-webkit-scrollbar-thumb {
    background: transparent;
}

.cardContainer .card {
    height: auto;
    min-width: 291px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: white;
    border-radius: 19px;
    align-items: center;
    box-shadow: 7px 8px 6px 0px #00000040;
    margin-bottom: 10px;
}

.cardContainer .card img {
    width: 245px;
    height: 245px;
    border-radius: 14px;
}

.cardContainer .card p {
    font-family: "Montserrat", "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    width: 245px;
    height: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
}

.cardContainer .card button {
    align-self: flex-end;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-weight: bold;
    font-family: "Montserrat", "Poppins", sans-serif;

}

.dotsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.dotsContainer .dot {
    width: 11px;
    height: 11px;
    border-radius: 100%;
    background-color: #414141;
    border: none;
    cursor: pointer;
    transition: width .2s ease-out;
    opacity: 0.51;
}

.dotsContainer .dotSelected {
    width: 24.57px;
    height: 11px;
    border-radius: 5px;
    background-color: #F5485D;
    cursor: pointer;
    border: none;
    transition: width .2s ease;
}

.containerButtonNew {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.containerButtonNew button {
    font-family: "Montserrat", "Poppins", sans-serif;
    height: 48px;
    background-color: #38187B;
    color: white;
    font-size: 20px;
    font-weight: 900;
    border: none;
    border-radius: 10px;
    padding: 5px 20px;
    cursor: pointer;
}