.MuiGrid-root {
    font-family: 'Poppins', sans-serif !important;
}

.MuiAutocomplete-popper {
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.MuiPaper-root{
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.MuiPickersPopper-root {
    background-color: white;
}

.MuiDialogContent-root {
    background-color: white;
}

.MuiDialogActions-root {
    background-color: white;
}

.MuiDialogTitle-root {
    background-color: white;
}

.drop-class-file-att {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    p {
        font-size: 1.2rem !important;
        font-weight: 400 !important;
    }
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='25%2c 19' stroke-dashoffset='18' stroke-linecap='square'/%3e%3c/svg%3e");
}

.MuiDropzoneArea-textContainer {
    display: flex;
    gap: 8px;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    border: none !important;
}

.succcess-import-att-content{
    animation: bg-change-animate .3s forwards ease-in;
}

@keyframes bg-change-animate {
    0% {
        background-color: white;
    }
    100% {
        background-color: #1D9BF0;
    }
}