@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.contentAyude {
    width: 100%;
    height: 996px;
    background-image: url("../../../assets/vaptpost/banner\ 2\ 1.png");
    background-position: center top;
    background-size: 100% auto;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    background-color: #371879;
    border-radius: 34px;
    padding: 50px 40px;
    font-family: "Inter", sans-serif;
}

.contentAyude .logo {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
}

.contentAyude .logo img {
    width: 189px;
    height: 48px;
}

.contentAyude .description {
    max-width: 600px;
}

.contentAyude .description .title {
    font-size: 38px;
    font-weight: 400;
    line-height: 45.99px;
    text-align: left;
    color: white;
}

.contentAyude .description .title .emphasis {
    font-weight: 900;
    background: linear-gradient(93.69deg, #FF6B7D 36.89%, #F5495E 51.33%, #B43949 92.87%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contentAyude .description .subtitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: white;
    margin-top: -18px;
}

.contentAyude .contentTitle {
    width: 100%;
    font-family: Inter;
    font-size: 55px;
    font-weight: 900;
    line-height: 66.56px;
    text-align: center;
    color: white;
}

.contentAyude .containerButtons {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
    gap: 15px;
}

.contentAyude .containerButtons a>button {
    min-height: 48px;
    border: none;
    padding: 5px 20px;
    border-radius: 10px;
    font-size: 20px;
    font-family: "Montserrat", "Poppins", sans-serif;
    font-weight: 900;
    color: white;
    background-color: #F5485D;
    cursor: pointer;
    transition: all ease-in-out .2s;
}

.contentAyude .containerButtons a:last-of-type>button {
    background-color: white;
    color: #F5485D;
}

.contentAyude .containerButtons a>button:hover {
    filter: brightness(120%) saturate(140%);
}

.containerNewPost {
    padding: 50px 40px;
    width: 100%;
}

.containerNewPost .newPostTitle {
    font-family: Inter;
    font-size: 55px;
    font-weight: 900;
    line-height: 66.56px;
    text-align: center;
    color: #38187B;

}