.step-progress-label {
    font-size: 12px !important;
    font-weight: 400 !important;
    font-family: 'Poppins', sans-serif !important;
    line-height: 18px !important;
    text-align: center !important;
    color: black !important;
}

.step-progress-btn {
    display: none !important;
}

.step-progress {

    // & span {
    //     background-color: #1D9BF0 !important;
    //     & svg {
    //         background-color: green !important;
    //     }
    // }
    &._2Jtxm._35Ago ._2kL0S {
        background-color: #1D9BF0 !important;
    }
}

.MuiStepLabel-label {
    font-size: 12px !important;
}

.MuiStep-root:first-child {
    .MuiStepConnector-line {
        display: none;
    }
}

.stepIndicatorNotSelected {
    width: 40px;
    height: 40px;
    background-color: #EAE7F3;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    transition: all .5s linear;
}

.stepIndicatorSelected {
    width: 48px;
    height: 48px;
    background-color: #1D9BF0;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: white;
    z-index: 10;
    transition: all .5s linear;
}

.stepIndicatorError {
    width: 40px;
    height: 40px;
    background-color: red;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    transition: all .5s linear;
}

.stepIndicatorChecked {
    width: 40px;
    height: 40px;
    background-color: #4db193;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    transition: all .5s linear;
}

.lineChecked {
    .MuiStepConnector-root {
        .MuiStepConnector-line {
            background-color: #4db193;
        }
    }
}

.lineError {
    .MuiStepConnector-root {
        .MuiStepConnector-line {
            background-color: red;
        }
    }
}

.MuiStepConnector-vertical:first-child {
    .MuiStepConnector-lineVertical {
        display: none;
    }
}


.MuiStepLabel-labelContainer {
    .MuiStepLabel-label {
        font-size: 14px !important;
        font-weight: 400;
        color: rgba(112, 112, 112, 1)
    }
}

.MuiStep-vertical {
    margin: 0 !important;
    padding: 0 !important;
}

.MuiStepper-vertical {
    margin: 20px 0px;
}



.labelMobileCanceled {
    .MuiStepLabel-labelContainer {
        .MuiStepLabel-label {
            font-size: 14px !important;
            font-weight: bold;
            color: red
        }
    }
}

.labelMobileChecked {
    .MuiStepLabel-labelContainer {
        .MuiStepLabel-label {
            font-size: 14px !important;
            font-weight: bold;
            color: #1D9BF0
        }
    }
}