.menu-side-bar{
    width: 100%;
    min-width: 250px;
    max-width: 250px;
}
.menu-side-bar #btn-menu-open{
    position: absolute;
} 

#icon-nav{
    // opacity: 1;
    width: 20px;
}