@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.container .arrows {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;

}

.cardContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 30px;
    overflow: auto;
    scroll-behavior: smooth;
}

.cardContainer::-webkit-scrollbar {
    width: 7px;
}

.cardContainer::-webkit-scrollbar-track {
    background: transparent;

}

.cardContainer::-webkit-scrollbar-thumb {
    background: transparent;
}

.cardContainer .card {
    /* height: 418px; */
    min-width: 287px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: white;
    border-radius: 15px;
    gap: 10px;
    align-items: center;
    transition: all ease .2s;
}

.cardContainer .card img {
    width: 100%;
    height: 255px;
    margin-top: 30px;
    border-radius: 14px;
}

.cardContainer .card p {
    font-family: "Montserrat", "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
}

.cardContainer .card button {
    cursor: pointer;
    font-family: "Montserrat", "Poppins", sans-serif;
    width: 168px;
    height: 29.32px;
    border-radius: 10px;
    background-color: #F5485D;
    color: white;
    font-size: 12px;
    font-weight: 900;
    border: none;
    transition: all ease-in-out .2s;
}

.cardContainer .card button:hover {
    transform: scale(1.05);
}

.dotsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.dotsContainer .dot {
    width: 11px;
    height: 11px;
    border-radius: 100%;
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
    transition: width .2s ease-out;
    opacity: 0.51;
}

.dotsContainer .dotSelected {
    width: 24.57px;
    height: 11px;
    border-radius: 5px;
    background-color: #F5485D;
    cursor: pointer;
    border: none;
    transition: width .2s ease;
}