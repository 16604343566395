.selectMenu {
    max-width: 200px;
    font-family: 'Poppins';
    position: relative;
}

.selectMenu .sBtnText {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selectMenu .selectBtn {
    display: flex;
    height: 40px;
    background: #fff;
    padding: 20px;
    font-size: 18px;
    font-weight: 400;
    border-radius: 28px;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    z-index: 10;

}

.selectBtn i {
    font-size: 15px;
}

.selectMenu .options {
    position: absolute;
    padding: 10px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    display: none;
    z-index: 11;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn .2s ease-out;
}

.options .option {
    display: flex;
    height: 40px;
    cursor: pointer;
    padding: 0 16px;
    align-items: center;
    justify-content: flex-start;
    border-radius: 28px;
    background-color: #fff;
}

.options .option:hover {
    background: #f2f2f2;
}

.option .optionText {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
}

.selectMenu .arrow {
    transition: all .2s ease-out;
    margin-left: 7px;
}

@-webkit-keyframes fadeIn {
    from {
        transform: scale(0.9)
    }

    to {
        transform: scale(0.9)
    }
}

@keyframes fadeIn {
    from {
        transform: scale(0.9)
    }

    to {
        transform: scale(1)
    }
}