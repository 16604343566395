#table-simulator {
    font-size: .8em;
    border-collapse: collapse;
    color: #878787;
    & th {
        border-right: 1px solid #878787;
        padding: 5px;
        &:last-child {
            border-right: none;
        }
    }

    & td {
        border-right: 1px solid #878787;
        padding: 5px;
        text-align: center;

        &:last-child {
            border-right: none;
        }
    }
}